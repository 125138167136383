import { useTranslation } from "react-i18next";


export const PERSONAL_ACCOUNT = '/personal_account'
export const DETAILS = '/personal_account/'
export const STATEMENT = '/personal_account/statement'
export const INTERNATIONAL_TRANSACTION_INFO = '/personal_account/statement/international_transaction_info'
export const TRANSFERS = '/personal_account/transfers'
export const INTRA_TRANSFERS = '/personal_account/intra_transfers'
export const EXCHANGE = '/personal_account/exchange'
export const HOME = '/'
export const ABOUT_US = '/about_us'
export const PRIVATE_BANKING = '/private_banking'
export const COMMERCIAL_BANKING = '/commercial_banking'
export const CARDS = '/cards'
export const DIGITAL_ASSETS_BANKING = '/digital_assets_banking'
export const TRADE_FINANCE = '/trade_finance'
export const FAQ = '/faq'
export const CONTACT = '/contact'
export const OPEN_ACCOUNT = '/open_account'
export const LOGIN = '/log_in'
export const MOBILE_LEGAL_INFORMATION = '/legal_information'
export const CHANGE_PASSWORD = '/personal_account/change_password'
export const FORGOT_PASSWORD = '/forgot_password'



// Function to get translated titles
export const useTranslatedTitles = () => {
    const { t } = useTranslation();
    return {
        about: t("about_us"),
        privateBanking: t("private_banking"),
        commercialBanking: t("commercial_banking"),
        cards: t("cards"),
        digitalAssets: t("digital_assets_banking"),
        tradeFinance: t("trade_finance"),
        faq: t("faq"),
        contact: t("contact"),
        openAccount: t("open_account"),
        login: t("login")
    };
};
