import React from 'react';
import s from "./TradeFinance.module.css";
import p2p_money from "../../assets/img/p2p_money.png";
import p2p_money_mobile from "../../assets/img/mobile/p2p_money_mobile.png";
import {motion} from 'framer-motion';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

const TradeFinance = () => {
    const {t, i18n} = useTranslation();

    const currentLang = i18n.language;
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        {t("trade_finance")}
                    </div>
                </div>

                <div className={s.color_background}>
                    <div className={s.content}>
                        <div className={s.who_we_desc_block}>
                            <div className={s.who_we_description}>
                                <div>
                              {t("trade_finance_page.sub_heading")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                            {t("trade_finance_page.paragraph1")}
                            </div>
                        </div>
                        <div className={s.right}>
                        {t("trade_finance_page.paragraph2")}
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                        {t("trade_finance_page.services.heading")}
                        </div>
                        <div className={classnames(s.flex, s.short_block)}>
                            <div className={classnames(s.left, s.short)}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li1")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li2")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li3")}
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(s.right, s.right_short_block)}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li4")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li5")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                    {t("trade_finance_page.services.li6")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                            {t("trade_finance_page.paragraph3")}
                            </div>
                        </div>
                        <div className={s.right}>
                        {t("trade_finance_page.paragraph4")}
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                        {t("trade_finance_page.cards.heading")}
                        </div>
                    </div>

                    <div className={s.cards_wrapper}>
                        <div className={s.flex}>
                            <div className={`${s.card_01} ${currentLang !== 'en' && s.increase_height}`}>
                                <div className={s.card_number}>
                                    01.
                                </div>
                                <div className={s.card_title}>
                                {t("trade_finance_page.cards.title1")}
                                </div>
                                <div className={s.card_text}>
                                {t("trade_finance_page.cards.desc1")}
                                </div>
                            </div>
                            <div className={`${s.card_02} ${currentLang !== 'en' && s.increase_height}`}>
                                <div className={s.card_number}>
                                    02.
                                </div>
                                <div className={s.card_title}>
                                {t("trade_finance_page.cards.title2")}
                                </div>
                                <div className={s.card_text}>
                                {t("trade_finance_page.cards.desc3")}
                                </div>
                            </div>
                        </div>
                        <div className={s.card_03}>
                            <div>
                                <div className={s.card_number}>
                                    03.
                                </div>
                                <div className={s.card_title}>
                                {t("trade_finance_page.cards.title3")}
                                </div>
                                <div className={s.card_text}>
                                {t("trade_finance_page.cards.desc3")}
                                </div>
                            </div>
                            <div className={s.coins_img_block}>
                                <img className={s.p2p_money} src={p2p_money} alt=""/>
                                <img className={s.p2p_money_mobile} src={p2p_money_mobile} alt=""/>
                            </div>

                        </div>

                    </div>

                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                            {t("trade_finance_page.paragraph5")}
                            </div>
                        </div>
                        <div className={s.right}>
                        {t("trade_finance_page.paragraph6")}
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                        {t("trade_finance_page.cost.heading")}
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                    {t("trade_finance_page.cost.li1")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                    {t("trade_finance_page.cost.li2")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                    {t("trade_finance_page.cost.li3")}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                    {t("trade_finance_page.cost.li4")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                    {t("trade_finance_page.cost.li5")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={s.color_background}>
                    <div className={s.content}>
                        <div className={s.who_we_desc_block}>
                            <div className={s.who_we_description}>
                                <div>
                                    <span className={s.white_bold}>{t("at")} GBH Coriolis Bank</span>  {t("trade_finance_page.desc_block.paragraph1")}
                                </div>
                                <div>
                                {t("trade_finance_page.desc_block.paragraph2")}
                                </div>
                                <div>
                                {t("trade_finance_page.desc_block.paragraph3")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                        {t("trade_finance_page.issues.heading")}
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                    {t("trade_finance_page.issues.li1")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                    {t("trade_finance_page.issues.li2")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                    {t("trade_finance_page.issues.li3")}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                    {t("trade_finance_page.issues.li4")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                    {t("trade_finance_page.issues.li5")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer} dangerouslySetInnerHTML={{__html: t("trade_finance_page.issues.paragraph")}} />
                           
                    </div>
                </div>
            </motion.div>

        </div>
    );
};

export default TradeFinance;
