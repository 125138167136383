import React, {useState} from 'react';
import s from "./MobileMenu.module.css";
import closePopup from '../../assets/img/mobile/close.png'
import classnames from "classnames";
import {NavLink, useNavigate} from "react-router-dom";
import {HOME, LOGIN, MOBILE_LEGAL_INFORMATION, OPEN_ACCOUNT} from "../../router/Constants";
import PublicRoutes from "../../router/PublicRoutes";
import CustomBtnPink from "../ui/customBtnPink/CustomBtnPink";
import CustomBtnTransparent from "../ui/customBtnTransparent/CustomBtnTransparent";
import { useTranslation } from 'react-i18next';
import { changeLanguage } from "../../i18n";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}

type RouteType = {
    path: string;
    title: string;
    component: JSX.Element;
};

const MobileMenu = ({isActive, setIsActive}: propsType) => {
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language); 
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()

    const redirect = (link: string) => {
        navigate(link)
        setIsActive(false)
    }

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleSelect = (lang: string) => {
        const newLang = lang;
        changeLanguage(newLang);
        setCurrentLang(newLang);
        setIsOpen(false);
    };

    const publicRoutes: RouteType[] = PublicRoutes()

    return (
        <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
            <div className={s.close_img_block}>
                <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
            </div>
            <div className={s.lang_dropdown}>
                <CustomBtnTransparent isLang rortate={isOpen} title={`${currentLang === 'en' ? 'EN' : 'RU'}`} onClick={handleToggle} />
                {isOpen && (
                  <div className={s.dropdown_menu}>
                    <div onClick={() => handleSelect('en')}>EN</div>
                    <div onClick={() => handleSelect('ru')}>RU</div>
                  </div>
                )}
              </div>
            <div className={s.links_block}>
                <NavLink to={HOME} className={s.link} onClick={() => setIsActive(false)}>
                    {t('home')}
                </NavLink>
                { publicRoutes.map((item: RouteType) => (
                    <NavLink key={item.path} className={s.link} to={item.path} onClick={() => setIsActive(false)}>
                        {item.title}
                    </NavLink>
                ))}
                <NavLink to={MOBILE_LEGAL_INFORMATION} className={s.link} onClick={() => setIsActive(false)}>
                    Legal information
                </NavLink>
            </div>
            <div className={s.btn_block}>
                <CustomBtnPink title={t('open_account')} onClick={() => redirect(OPEN_ACCOUNT)}/>
            </div>
            <div className={s.btn_block}>
                <CustomBtnTransparent title={t("login")} isLogin onClick={() => redirect(LOGIN)}/>
            </div>
            <div className={s.mobile_menu_footer}>
                <div className={s.copyright}>
                    © 2023 GBH Coriolis Bancorp
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
