import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./Header.module.css";
import NavBar from "../navBar/NavBar";
import logo from "../../assets/img/logo.png";
import whatsapp from "../../assets/img/telegramm.png";
import CustomBtnTransparent from "../ui/customBtnTransparent/CustomBtnTransparent";
import CustomBtnPink from "../ui/customBtnPink/CustomBtnPink";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HOME, LOGIN, OPEN_ACCOUNT } from "../../router/Constants";
import menu from "../../assets/img/mobile/menu.png";
import { userSlice } from "../../store/reducers/UserSlice";
import { useAppDispatch } from "../../hooks/redux";
import { changeLanguage } from "../../i18n"; // Import changeLanguage

type PropsType = {
    setIsMobileMenuActive: (boolean: boolean) => void;
};

const Header = (props: PropsType) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavShow, setIsNavShow] = useState(false);
    const [isLoginPage, setIsLoginPage] = useState(false);
    const [currentLang, setCurrentLang] = useState(i18n.language); 
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("open_account")) {
            setIsNavShow(false);
        } else if (location.pathname.includes("log_in")) {
            setIsNavShow(false);
            setIsLoginPage(true);
        } else if(location.pathname.includes("forgot_password")){
          setIsNavShow(false);
          setIsLoginPage(true);
        } else {
            setIsNavShow(true);
            setIsLoginPage(false);
        }
    }, [location.pathname]);

    const { setMessageSentSuccess } = userSlice.actions;
    const dispatch = useAppDispatch();

    const redirect = () => {
        navigate(LOGIN);
        dispatch(setMessageSentSuccess(null));
    };


    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleSelect = (lang: string) => {
        const newLang = lang;
        changeLanguage(newLang);
        setCurrentLang(newLang); // Update local state to reflect the change
        setIsOpen(false);
    };

    return (
      <div className={s.wrapper}>
        <div className={s.content}>
          <Link to={HOME}>
            <img src={logo} alt="logo" />
          </Link>
          {isNavShow ? (
            <div className={s.buttons_block}>
              <div className={s.whatsapp_wrapper}>
                <a href="https://wa.me/+37254640735" target="_blank">
                  <img style={{ marginTop: 3 }} src={whatsapp} alt="whatsapp" />
                </a>
              </div>
              <div className={s.login_btn_wrapper}>
                <CustomBtnTransparent title={t('login')} isLogin onClick={() => navigate(LOGIN)} />
              </div>
              <div className={s.btn_open_block}>
                <CustomBtnPink title={t('open_account')} onClick={() => navigate(OPEN_ACCOUNT)} />
              </div>
              <div>
                <CustomBtnTransparent isLang rortate={isOpen} title={`${currentLang === 'en' ? 'EN' : 'RU'}`} onClick={handleToggle} />
                {isOpen && (
                  <div className={s.dropdown_menu}>
                    <div onClick={() => handleSelect('en')}>EN</div>
                    <div onClick={() => handleSelect('ru')}>RU</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={s.buttons_block}>
              {isLoginPage ? (
                <CustomBtnTransparent isBack title={t('back')} onClick={() => navigate(-1)} />
              ) : (
                <CustomBtnTransparent title={t('login')} isLogin onClick={() => redirect()} />
              )}
            </div>
          )}
          <div className={s.mobile_flex}>
            <div className={s.whatsapp_wrapper_mobile}>
              <a href="https://wa.me/+37254640735" target="_blank">
                <img style={{ marginTop: 3 }} src={whatsapp} alt="whatsapp" />
              </a>
            </div>
            <div>
              <img onClick={() => props.setIsMobileMenuActive(true)} src={menu} alt="menu" />
            </div>
          </div>
        </div>
        {isNavShow && (
          <div className={s.navbar_wrapper}>
            <NavBar />
          </div>
        )}
      </div>
    );
};

export default Header;
