import React from 'react';
import s from './Home.module.css'
import CustomCard from "../../components/ui/customCard/CustomCard";
import CustomBtnTransparent from "../../components/ui/customBtnTransparent/CustomBtnTransparent";
import phone from '../../assets/img/phone.png'
import mainBG from '../../assets/img/main_page_bg.png'
import main_img_mobile from '../../assets/img/mobile/main_bg_mobile.svg'
import arrow_main_screen from '../../assets/img/main_screen_arrow.png'
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {NavLink, useNavigate} from "react-router-dom";
import {
    ABOUT_US,
    CARDS,
    COMMERCIAL_BANKING,
    DIGITAL_ASSETS_BANKING, OPEN_ACCOUNT,
    PRIVATE_BANKING,
    TRADE_FINANCE
} from "../../router/Constants";
import {useFormik} from "formik";
import {sendFormDataThunk} from "../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import MyPopupSendSuccess from "../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isSuccess: boolean | null = useAppSelector(state => state.userReducer.isMessageSentSuccess)
    const formikMail = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: values => {
            const data = {
                subject: "Get Started",
                data: values
            }
            dispatch(sendFormDataThunk(data))
        },
    });

    const cards = [
        {
            title: t('services.private_banking'),
            short: "PB",
            description: t('services.private_banking_description'),
            link: PRIVATE_BANKING
        },
        {
            title: t('services.commercial_banking'),
            short: "CB",
            description: t('services.commercial_banking_description'),
            link: COMMERCIAL_BANKING
        },
        {
            title: t('services.cards'),
            short: "C",
            description: t('services.cards_description'),
            link: CARDS
        },
        {
            title: t('services.digital_assets_banking'),
            short: "DAB",
            description: t('services.digital_assets_banking_description'),
            link: DIGITAL_ASSETS_BANKING
        },
        {
            title: t('services.trade_finance'),
            short: "TF",
            description: t('services.trade_finance_description'),
            link: TRADE_FINANCE
        },
    ]

    return (
        <div className={s.wrapper}>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >

                {
                    isSuccess
                        ? <MyPopupSendSuccess/>
                        : <></>
                }

                <div className={s.content}>
                    <div className={s.main_screen}>
                        <div>
                            <img className={s.main_bg} src={mainBG} alt=""/>
                        </div>
                        <div className={s.main_title_block}>
                            <div >
                                <div className={`${s.trusted} ${currentLang !== 'en' && s.small_font }`}>
                                    {t('trusted')}
                                </div>
                                <div className={`${s.partner} ${currentLang !== 'en' && s.small_font }`}>
                                    {t("partner")}
                                </div>
                                <div className={`${s.for_your} ${currentLang !== 'en' && s.small_font }`}>
                                    {t('for_your')} <img src={arrow_main_screen} alt="" />
                                </div>
                                <div className={`${s.digital_journey} ${currentLang !== 'en' && s.small_font }`}>
                                    {t('digital_journey')!}
                                </div>
                            </div>
                            <div className={s.my_input_block}>
                                <form onSubmit={formikMail.handleSubmit}>
                                    <input type="email"
                                           className={s.my_input}
                                           placeholder={t("enter_your_email")}
                                           id="email"
                                           name="email"
                                           required
                                           onChange={formikMail.handleChange}
                                           value={formikMail.values.email}
                                    />
                                    <div className={s.main_btn_block}>
                                        <CustomBtnPink title={t("get_started")} type={"submit"}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.main_screen_mobile}>
                        <div className={s.title_block}>
                            <div className={s.title_mobile}>
                                {t("trusted")}
                            </div>
                            <div className={s.title_mobile_margin}>
                                {t("partner")}
                            </div>
                            <div className={s.title_mobile}>
                                {t("for_your")}
                                <div>
                                    <img className={s.arrow_main_screen_mobile} src={arrow_main_screen} alt=""/>
                                </div>
                            </div>
                            <div className={s.title_italic}>
                                {t("digital_journey")!} 
                            </div>
                        </div>

                        <div className={s.main_img_mobile_block}>
                            <img className={s.main_img_mobile} src={main_img_mobile} alt=""/>
                        </div>

                        <div className={s.input_wrapper}>
                            <div className={s.my_input_block}>
                                <input type="text" className={s.my_input} placeholder={t("enter_your_email")}/>
                                <div className={s.main_btn_block}>
                                    <CustomBtnPink title={t("get_started")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.who_we}>
                    <div className={s.content}>
                        <div className={s.who_title}>
                            {t('who_we_are')}?
                        </div>
                        <div className={s.who_we_desc_block}>
                            <div className={s.who_we_description}>
                                <div>
                                    <span className={s.color}>GBH Coriolis Bank</span> {t('who_paragraph.p1')}
                                </div>
                                <div>
                                {t('who_paragraph.p2')}
                                </div>
                                <div>
                                   {t('who_paragraph.p3')}
                                </div>
                                <div>
                                   {t('who_paragraph.p4')}
                                </div>
                            </div>
                            <div className={s.btn_block}>
                                <NavLink to={ABOUT_US} reloadDocument style={{textDecoration: 'none'}}>
                                    <CustomBtnTransparent title={t('read_more')}/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.cards_wrapper}>
                        <div className={s.cards_title}>
                            {t('services_we_provide')}
                        </div>
                        {
                            cards.map((card) => {
                                return (
                                    <div className={s.card_block} key={card.title}>
                                        <NavLink to={card.link} reloadDocument>
                                            <CustomCard
                                                title={card.title}
                                                short={card.short}
                                                description={card.description}
                                            />
                                        </NavLink>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.open_acc_block}>
                        <div className={s.open_account_title}>GBH Coriolis Bank</div>
                        <div className={s.open_account_desc}>
                            {t('acc_open_desc')}
                        </div>
                        <div className={s.open_account_desc_mobile}>
                        {t('acc_open_desc')}
                        </div>
                        <div className={s.btn_open_account}>
                            <CustomBtnPink title={t('open_account')} onClick={() => navigate(OPEN_ACCOUNT)}/>
                        </div>
                        <img src={phone} className={s.phone} alt=""/>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Home;
