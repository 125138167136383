import React from 'react';
import s from "./DigitalAssetsBanking.module.css";
import coins from "../../assets/img/coins.png";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {useNavigate} from "react-router-dom";
import {OPEN_ACCOUNT} from "../../router/Constants";
import {motion} from 'framer-motion';
import { useTranslation } from 'react-i18next';

const DigitalAssetsBanking = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >

                <div className={s.content}>

                    <div className={s.title}>
                        {t("digital_assets_banking")}
                    </div>

                    <div className={s.who_we_desc_block}>
                        <div className={s.who_we_description}>
                            <div>
                            {t("digital_asset_page.sub_heading")}
                            </div>
                        </div>
                    </div>

                    <div className={s.coins_block}>

                        <div className={s.coins_img_block}>
                            <img src={coins} alt=""/>
                        </div>
                        <div className={s.coins_description_block}>
                        {t("digital_asset_page.card1")}
                        </div>


                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                            {t("digital_asset_page.desc1")}
                            </div>
                        </div>
                        <div className={s.right}>
                        {t("digital_asset_page.desc2")}
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                        {t("digital_asset_page.services.heading")}
                        </div>
                        <div className={s.flex}>
                            <div className={s.left_list_block}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                        {t("digital_asset_page.services.li1")}
                                        </div>
                                        <div className={s.line_text}>
                                        {t("digital_asset_page.services.li1desc")}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                        {t("digital_asset_page.services.li2")}
                                        </div>
                                        <div className={s.line_text}>
                                        {t("digital_asset_page.services.li2desc")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.right_list_block}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                        {t("digital_asset_page.services.li3")}
                                        </div>
                                        <div className={s.line_text}>
                                        {t("digital_asset_page.services.li3desc")}
                                        </div>
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                        {t("digital_asset_page.services.li14")}
                                        </div>
                                        <div className={s.line_text}>
                                        {t("digital_asset_page.services.li4desc")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.gradient_block}>
                        <div className={s.open_text}>
                        {t("digital_asset_page.card2")}
                        </div>
                        <div className={s.open_text}>
                        {t("digital_asset_page.card3")}
                        </div>
                        <div className={s.btn_block}>
                            <div className={s.btn_wrapper}>
                                <CustomBtnPink title={t("open_account")} onClick={() => navigate(OPEN_ACCOUNT)}/>
                            </div>
                        </div>
                    </div>

                </div>
            </motion.div>

        </div>
    );
};

export default DigitalAssetsBanking;
