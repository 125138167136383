import React, {CSSProperties} from 'react';
import s from './CustomBtnTransparent.module.css'
import login_arrow from '../../../assets/img/log_in_arrow.png'
import backArrow from '../../../assets/img/arrow-left.png'

type PropsType = {
    title: string,
    onClick?: () => void;
    isLogin?: boolean
    isBack?: boolean
    isLang?: boolean
    rortate?: boolean
    style?:CSSProperties
}
const CustomBtnTransparent:React.FC<PropsType> = ({isBack,title, isLang, rortate, isLogin, ...props}) => {
    return (
        <button className={s.my_btn} {...props}>
            {isLogin ? <img src={login_arrow} alt=""/> : <></>}
            {isBack ? <img src={backArrow} alt=""/> : <></>}
            {title}
            {isLang && <span className={`${s.arrow} ${rortate ? s.arrow_rotated : "" }`}>▼</span> }
        </button>
    );
};

export default CustomBtnTransparent;
