import React from 'react';
import s from "./Cards.module.css";
import cards from "../../assets/img/cards.png";
import cards_mobile from "../../assets/img/mobile/cards_mobile.png";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {OPEN_ACCOUNT} from "../../router/Constants";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Cards = () => {
const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        {t('cards')}
                    </div>
                    <div className={s.who_we_desc_block}>
                        <div className={s.who_we_description}>
                            <div>
                               {t("card_page.sub_heading1")}
                            </div>
                            <div>
                            {t("card_page.sub_heading2")}
                            </div>
                        </div>
                    </div>

                    <div className={s.cards_block}>
                        <div className={s.flex}>

                            <div className={s.cards_description_block}>
                                <div className={s.cards_description_content}>
                                {t("card_page.card.paragraph1")}
                                </div>
                                <div className={s.cards_description_content}>
                                {t("card_page.card.paragraph2")}
                                </div>
                                <div className={s.cards_description_content}>
                                {t("card_page.card.paragraph3")}
                                </div>
                                <div className={s.cards_button_block}>
                                    <div className={s.cards_wrapper}>
                                        <CustomBtnPink title={t('sign_up')} onClick={() => navigate(OPEN_ACCOUNT)}/>
                                    </div>
                                </div>
                            </div>
                            <div className={s.cards_img_block}>
                                <img src={cards_mobile} className={s.cards_mobile} alt=""/>
                                <img src={cards} className={s.cards} alt=""/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                            {t("card_page.desc1")}
                            </div>
                            <div>
                            {t("card_page.desc2")}
                            </div>
                        </div>
                        <div className={s.right}>
                        {t("card_page.desc3")}
                        </div>
                    </div>
                </div>

                <div className={s.gradient_block}>
                {t("card_page.headline")}
                </div>
            </motion.div>
        </div>
    );
};

export default Cards;
