import axios from "axios";
import {LoginDataType} from "../store/reducers/ActionCreators";

let baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: 'https://api.gbhcoriolis.net',
    ...baseSettings
}) as any

export const Api = {
    registration(data: LoginDataType) {
        return instance.post('api/logins', data)
    },
    forgotPassword(login: string, email: string) {
        return instance.post('api/forgots/passwords', {login, email})
    },
    getAccountsList (token: string) {
        return instance.get('/api/accounts/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    sendTransfer (token: string, data: any) {
        return instance.post('/api/creates/internationals/transactions', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
    sendIntraTransfer (token: string, data: any) {
        return instance.post('/api/creates/internals/transactions', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
    getTransactionsList (token: string) {
        return instance.get('/api/user/transactions/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    getInternationalTransactionDetails (token: string, transactionNumber: string) {
        return instance.post('/api/transactions/infos', {transactionNumber : transactionNumber}, {headers: {Authorization: `Bearer ${token}`}})
    },
    changePassword (token: string, dataForPassword: any) {
        return instance.post('/api/user/password/change', dataForPassword, {headers: {Authorization: `Bearer ${token}`}})
    }
}

export const SenderApi = {
    sendFormData (data: { project: string; subject: string; data: { name: string; email: string; phone: string; message: string; }; }) {
        return instance.post('/api/universals/senders', data)
    }
}


