import React, {useEffect, useState} from 'react';
import s from './Login.module.css'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {LoginThunk} from "../../store/reducers/ActionCreators";
import {Link, useNavigate} from "react-router-dom";
import {DETAILS, OPEN_ACCOUNT, FORGOT_PASSWORD} from "../../router/Constants";
import CustomInput from "../../components/ui/customInput/CustomInput";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import { useTranslation } from 'react-i18next';
import {motion} from 'framer-motion';

const Login = () => {
    const {t} =useTranslation();

    const authStatus = useAppSelector(state => state.userReducer.error)

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const [loginData, setLoginData] = useState({
        login: "",
        password: ""
    })
    const [error, setError] = useState(false)

    useEffect(() => {
        if (authStatus === 200) {
            navigate(DETAILS)
        }

        if (authStatus === 403) {
            setError(true)
        }
    }, [authStatus])

    const handleLogin = async () => {
        await dispatch(LoginThunk(loginData))
    }

    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        {t("login")}
                    </div>
                    <div className={s.inputs_block}>
                        <div className={s.login_input_block}>
                            <CustomInput id={"login"}
                                         name={"login"}
                                         type={"text"}
                                         onChange={(e) =>
                                             setLoginData({
                                                 ...loginData,
                                                 login: e.currentTarget.value
                                             })}
                                         value={loginData.login}
                                         placeholder={`${t('login')} *`}
                                         required
                            />
                        </div>
                        <div className={s.password_input_block}>
                            <CustomInput id={"password"}
                                         name={"password"}
                                         type={"password"}
                                         onChange={(e) =>
                                             setLoginData({
                                                 ...loginData,
                                                 password: e.currentTarget.value
                                             })}
                                         value={loginData.password}
                                         placeholder={`${t('password')} *`}
                                         password
                                         required
                            />
                        </div>
                        <span className={s.forgot_password}><Link className={s.link} to={FORGOT_PASSWORD}>{t('forgot_password')}</Link></span>
                        <span className={s.link_block}>
                        {t('dont_have_account')}<Link className={s.link} to={OPEN_ACCOUNT}> {t('sign_up')}</Link>
                    </span>
                        <div className={s.btn_block}>
                            <CustomBtnPink title={t('login')} onClick={() => {
                                handleLogin()
                            }}/>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Login;
