import React from 'react';
import s from "./CommercialBanking.module.css";
import cash from "../../assets/img/cash.png";
import coins from "../../assets/img/coins_in_hand.png";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const CommercialBanking = () => {
    const {t} = useTranslation();
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                       {t('commercial_banking')} 
                    </div>

                    <div className={s.cards_wrapper}>
                        <div className={s.flex}>
                            <div className={s.card_01}>
                                <div className={s.card_number}>
                                    01.
                                </div>
                                <div className={s.card_text} dangerouslySetInnerHTML={ {__html: t("commercial_banking_page.card1")}} />
                                    {/* Businesses can also benefit from the private banking touch! Our International
                                    Commercial
                                    Banking services are designed for enterprises that are run by globally mobile
                                    families.
                                    If you are looking for a reliable global trade partner to help you handle
                                    transactions
                                    and create wealth across borders, talk to our expert bankers in the areas of complex
                                    payment transactions and trade finance. With our international <br/>
                                    expertise and network, we are  <br className={s.break}/> a valued <br className={s.break_desktop}/>
                                    partner when it <br/>
                                    comes to accurately <br/>
                                    assessing markets <br/>
                                    and managing <br/>
                                    risks..
                                </div> */}
                                <div className={s.cash_block}>
                                    <img src={cash} alt=""/>
                                </div>
                            </div>
                            <div className={s.card_02}>
                                <div className={s.card_number}>
                                    02.
                                </div>
                                <div className={s.card_text}>
                                {t("commercial_banking_page.card2")}
                                    </div>
                                    
                            </div>
                        </div>
                        <div className={s.card_03}>
                            <div>
                                <div className={s.card_number}>
                                    03.
                                </div>
                                <div className={s.card_text}>
                                    {t("commercial_banking_page.card3")}
                                </div>
                            </div>
                            <div className={s.coins_block}>
                                <img src={coins} alt=""/>
                            </div>

                        </div>

                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title} dangerouslySetInnerHTML={ {__html: t("commercial_banking_page.mca.desc")}} />
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                       {t("commercial_banking_page.mca.li1")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                       {t("commercial_banking_page.mca.li2")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t("commercial_banking_page.mca.li3")}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t("commercial_banking_page.mca.li4")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t("commercial_banking_page.mca.li5")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                        {t("commercial_banking_page.digital_channels.desc1")}
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li1")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li2")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li3")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li4")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li5")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li6")}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li7")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li8")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li9")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li10")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li11")}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}></div>
                                    <div className={s.gradient_text}>
                                    {t("commercial_banking_page.digital_channels.li12")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer}>
                        {t("commercial_banking_page.digital_channels.desc2")}
                        </div>
                    </div>

                </div>
            </motion.div>
        </div>
    );
};

export default CommercialBanking;
