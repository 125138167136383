import React from 'react';
import s from './PrivateBanking.module.css'
import grow from '../../assets/img/grow.png'
import cash from '../../assets/img/cash.png'
import check_circle from '../../assets/img/check_circle.svg'
import {motion} from 'framer-motion';
import { useTranslation } from 'react-i18next';

const PrivateBanking = () => {
    const { t } = useTranslation();
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        {t("private_banking")}
                    </div>

                    <div className={s.cards_wrapper}>
                        <div>
                            <div className={s.card_01}>
                                <div className={s.card_number}>
                                    01.
                                </div>
                                <div className={s.card_text} dangerouslySetInnerHTML={ {__html: t("private_banking_page.card1.paragraph1")}} />
                                    
                                <div className={s.card_bold} dangerouslySetInnerHTML={ {__html: t("private_banking_page.card1.paragraph2")}} />
                                    
                                
                                <div className={s.cash_block}>
                                    <img src={cash} alt=""/>
                                </div>
                            </div>
                            <div className={s.mobile_card_2}>
                                <div className={s.card_number}>
                                    02.
                                </div>
                                <div className={s.card_text}>
                                  { t("private_banking_page.card2.paragraph1")}
                                </div>
                                <div>
                                    <img className={s.grow} src={grow} alt=""/>
                                </div>
                            </div>
                            <div className={s.card_03}>
                                <div className={s.card_number}>
                                    03.
                                </div>
                                <div className={s.card_text}>
                                { t("private_banking_page.card3.paragraph1")}
                                </div>
                            </div>
                        </div>
                        <div className={s.card_02}>
                            <div className={s.card_number}>
                                02.
                            </div>
                            <div className={s.card_text}>
                            { t("private_banking_page.card2.paragraph1")}
                            </div>
                            <div>
                                <img className={s.grow} src={grow} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            {t('private_banking_page.services.desc')}:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li1')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li2')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li3')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li4')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li5')}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li6')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li7')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li8')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li9')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                    {t('private_banking_page.services.li10')}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                        {t('private_banking_page.relation.desc1')}
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                    {t('private_banking_page.relation.li1')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                    {t('private_banking_page.relation.li2')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                    {t('private_banking_page.relation.li3')}
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                    {t('private_banking_page.relation.li4')}
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                    {t('private_banking_page.relation.li5')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer}>
                        {t('private_banking_page.relation.desc2')}
                        </div>
                    </div>

                </div>
            </motion.div>
        </div>
    );
};

export default PrivateBanking;
