import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from './helpers/locales/en.json';
import translationRu from "./helpers/locales/ru.json";

const resources = {
  en: { translation: translationEn },
  ru: { translation: translationRu }
};

const preferredLanguage = localStorage.getItem("I18N_LANGUAGE") || "en";

i18next
  .use(initReactI18next) 
  .init({
    resources,
    lng: preferredLanguage,
    fallbackLng: "en",
    interpolation: { escapeValue: false }
  })
  .catch(error => {
    console.error("i18next initialization failed:", error);
  });

export const changeLanguage = (lang: string) => {
  i18next.changeLanguage(lang).then(() => {
    localStorage.setItem("I18N_LANGUAGE", lang);
  });
};

export default i18next;
