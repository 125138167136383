import React from 'react';
import { useFormik } from 'formik';
import CustomInput from "../ui/customInput/CustomInput";
import s from './ContactUsForm.module.css'
import CustomBtnPink from "../ui/customBtnPink/CustomBtnPink";
import {useAppDispatch} from "../../hooks/redux";
import {sendFormDataThunk} from "../../store/reducers/ActionCreators";
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
    phone: yup.number().required('Required field'),
    email: yup.string().required('Required field').email('invalid format')
})

const ContactUsForm = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch()

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                subject: "contact form",
                data: values
            }
            dispatch(sendFormDataThunk(data))
        },
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={s.flex}>
                <div className={s.short_input}>
                    <CustomInput
                        id="name"
                        name="name"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placeholder={`${t('name')}*`}
                        pattern="[a-z, A-Z]*"
                    />
                </div>
                <div className={s.short_input}>
                    <CustomInput
                        id="phone"
                        name="phone"
                        type="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        placeholder={`${t('phone')}*`}
                        pattern="[0-9, +]*"
                        isError={formik.errors.phone ? s.input_error : ""}
                    />
                </div>
            </div>

            <CustomInput
                id="email"
                name="email"
                type="email"
                placeholder={"Email *"}
                onChange={formik.handleChange}
                value={formik.values.email}
                isError={formik.errors.email ? s.input_error : ""}
            />

            <textarea
                id="message"
                name="message"
                placeholder={t('message')}
                onChange={formik.handleChange}
                value={formik.values.message}
                className={s.textarea}
            />
            <div className={s.btn_block}>
                <div className={s.btn_wrapper}>
                    <CustomBtnPink type="submit" title={t('contact_us')}/>
                </div>
            </div>
        </form>
    );
};

export default ContactUsForm
