import {AppDispatch} from "../store";
import {Api, SenderApi} from "../../api/Api";
import {userSlice} from "./UserSlice";
import {debuglog} from "util";

export type LoginDataType = {
    login: string,
    password: string,
}

export const LoginThunk = (data: LoginDataType) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.registration(data)
        await dispatch(userSlice.actions.userAuthorizationError(response.status))
        await dispatch(userSlice.actions.userAuthorizationSuccess(response.data))
    } catch (e: any) {
        await dispatch(userSlice.actions.userAuthorizationError(e.response.status))
    }
}
export const ForgotPasswordThunk = (login:string, email: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.forgotPassword(login, email)
        dispatch(userSlice.actions.setMessageSentSuccess(true))
    } catch (e: any) {
           dispatch(userSlice.actions.setFrogotPasswordError(e.response.data))
    }
}

export const LogoutThunk = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(userSlice.actions.signOut())
    } catch (e: any) {
        console.log(e)
    }
}

export const GetAccountsThunk = (token: string) => async (dispatch: AppDispatch) => {
    console.log("get")
    try {
        const response = await Api.getAccountsList(token)
        dispatch(userSlice.actions.setUserAccounts(response.data))
    } catch (e: any) {
        console.log(e)
    }
}

export const GetTransactionsListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getTransactionsList(token)
        dispatch(userSlice.actions.setUserTransactions(response.data))
    } catch (e: any) {
        console.log(e)
    }
}

export const SendTransferThunk = (token: string, dataForTransaction: any) => async (dispatch: AppDispatch) => {
    try {
        await Api.sendTransfer(token, dataForTransaction)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(true))
    } catch (e) {
        console.log(e)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(false))
    }
}

export const sendIntraTransfer = (token: string, dataForTransaction: any) => async (dispatch: AppDispatch) => {
    try {
        await Api.sendIntraTransfer(token, dataForTransaction)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(true))
    } catch (e) {
        console.log(e)
        dispatch(userSlice.actions.setTransactionWasSendSuccess(false))
    }
}

export const getInternationalTransactionDetailsThunk = (token: string, transactionNumber: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getInternationalTransactionDetails(token, transactionNumber)
        dispatch(userSlice.actions.setInternationalTransactionDetails(response.data))
    } catch (e) {
        console.log(e)
    }
}

export const sendFormDataThunk = (data: any) => async (dispatch: AppDispatch) => {
    try {
        await SenderApi.sendFormData(data)
        await dispatch(userSlice.actions.setMessageSentSuccess(true))
    } catch (e) {
        dispatch(userSlice.actions.setMessageSentSuccess(true))
    }
}
export const changePasswordThunk = (token: string, dataForPassword: any) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.changePassword(token, dataForPassword)
        console.log(res)
        dispatch(userSlice.actions.setChangePasswordSuccess(true))
    } catch (e) {
        console.log(e)
        dispatch(userSlice.actions.setChangePasswordSuccess(false))
    }
}
