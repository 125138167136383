// src/router/PublicRoutes.tsx
import React from "react";
import {
    ABOUT_US,
    CARDS,
    COMMERCIAL_BANKING,
    CONTACT,
    DIGITAL_ASSETS_BANKING,
    FAQ,
    PRIVATE_BANKING,
    TRADE_FINANCE,
} from "./Constants";
import { useTranslatedTitles } from "./Constants";
import AboutUs from "../publicPages/aboutUs/AboutUs";
import PrivateBanking from "../publicPages/privateBanking/PrivateBanking";
import CommercialBanking from "../publicPages/commercialBanking/CommercialBanking";
import Cards from "../publicPages/cards/Cards";
import DigitalAssetsBanking from "../publicPages/digitalAssetsBanking/DigitalAssetsBanking";
import Faq from "../publicPages/faq/Faq";
import TradeFinance from "../publicPages/tradeFinance/TradeFinance";
import Contact from "../publicPages/contact/Contact";

const PublicRoutes = () => {
    const titles = useTranslatedTitles();

    // Define the routes with translated titles
    const routes = [
        {
            path: ABOUT_US,
            title: titles.about,
            component: <AboutUs />,
        },
        {
            path: PRIVATE_BANKING,
            title: titles.privateBanking,
            component: <PrivateBanking />,
        },
        {
            path: COMMERCIAL_BANKING,
            title: titles.commercialBanking,
            component: <CommercialBanking />,
        },
        {
            path: CARDS,
            title: titles.cards,
            component: <Cards />,
        },
        {
            path: DIGITAL_ASSETS_BANKING,
            title: titles.digitalAssets,
            component: <DigitalAssetsBanking />,
        },
        {
            path: TRADE_FINANCE,
            title: titles.tradeFinance,
            component: <TradeFinance />,
        },
        {
            path: FAQ,
            title: titles.faq,
            component: <Faq />,
        },
        {
            path: CONTACT,
            title: titles.contact,
            component: <Contact />,
        },
    ];

    return routes;
};

export default PublicRoutes;
