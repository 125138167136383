import React, { useEffect, useState } from 'react';
import s from './ForgotPassword.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ForgotPasswordThunk } from '../../store/reducers/ActionCreators';
import { Link, useNavigate } from 'react-router-dom';
import { DETAILS, OPEN_ACCOUNT } from '../../router/Constants';
import CustomInput from '../../components/ui/customInput/CustomInput';
import CustomBtnPink from '../../components/ui/customBtnPink/CustomBtnPink';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { userSlice } from '../../store/reducers/UserSlice';
import MyPopupSendSuccess from '../../components/ui/MyPopupSendSuccess/MyPopupSendSuccess';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const { setFrogotPasswordError } = userSlice.actions
    const isSuccess: boolean | null = useAppSelector(state => state.userReducer.isMessageSentSuccess);
    const isError: any | null = useAppSelector(state => state.userReducer.isForgotPasswordError);

    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [login, setLogin] = useState('');
    const [error, setError] = useState(false);
    useEffect(() => {
        if (isError) {
            setError(true);
        }
    }, [isError]);

    const handleLogin = async () => {
        await dispatch(ForgotPasswordThunk(login, email));
    };

    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                className={s.motion_div}>
                {isSuccess ? (
                    <MyPopupSendSuccess message="The new password was sent to your email address" />
                ) : (
                    <div className={s.content}>
                        <div className={s.title}>{t('forgot_password')}</div>
                        <span className={s.link_block}>{t('enter_your_account')}</span>
                        <div className={s.inputs_block}>
                            <div className={s.input_block}>
                                <CustomInput
                                    id={'email'}
                                    name={'email'}
                                    type={'text'}
                                    onChange={e => {
                                        setEmail(e.currentTarget.value);
                                        setError(false);
                                        dispatch(setFrogotPasswordError(null))
                                    }}
                                    value={email}
                                    placeholder={`${t('enter_your_email')} *`}
                                    required
                                />
                            </div>
                            <div className={s.input_block}>
                                <CustomInput
                                    id={'login'}
                                    name={'login'}
                                    type={'text'}
                                    onChange={e => { setLogin(e.currentTarget.value); setError(false); dispatch(setFrogotPasswordError(null)) }}
                                    value={login}
                                    placeholder={`${t('login')} *`}
                                    required
                                />
                            </div>
                            {error && <span className={s.error_block}>{isError}</span>}
                            <div className={s.btn_block}>
                                <CustomBtnPink
                                    title={t('continue')}
                                    onClick={() => {
                                        handleLogin();
                                    }}
                                />
                            </div>
                            <span className={s.link_block}>
                                {t('back_to')}
                                <Link className={s.link} to={OPEN_ACCOUNT}>
                                    {' '}
                                    {t('sign_up')}
                                </Link>
                            </span>
                        </div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default ForgotPassword;
