import React from 'react';
import s from './AboutUs.module.css'
import puzzles from '../../assets/img/puzzles.png'
import puzzlesMobile from '../../assets/img/mobile/puzzless.png'
import coin from '../../assets/img/bitcoin.png'
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {OPEN_ACCOUNT} from "../../router/Constants";
import {useNavigate} from "react-router-dom";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >

                <div className={s.content}>
                    <div className={s.title}>
                       {t('about_us')} 
                    </div>
                </div>

                <div className={s.gradient_block}>
                   {t('about_page.sub_heading')}
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
{t('about_page.paragraph1')}
                        </div>
                        <div className={s.right}>
                            <div className={s.mb}>
                            {t('about_page.paragraph2')}
                            </div>
                            <div>
                            {t('about_page.paragraph3')}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.puzzles_block}>
                        <div className={s.flex}>
                            <div className={s.puzzles_img_block}>
                                <img className={s.puzzles_mob} src={puzzlesMobile} alt=""/>
                                <img className={s.puzzles} src={puzzles} alt=""/>
                            </div>
                            <div className={s.puzzles_description_block}>
                                <div className={s.puzzles_description_content}>
                                {t('about_page.paragraph4')}
                                </div>
                                <div className={s.puzzles_description_content}>
                                {t('about_page.paragraph5')}
                                </div>
                                <div className={s.puzzles_button_block}>
                                    <div className={s.button_wrapper}>
                                        <CustomBtnPink title={t("sign_up")} onClick={() => navigate(OPEN_ACCOUNT)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                        {t('about_page.paragraph6')}
                        </div>
                        <div className={s.right}>
                        {t('about_page.paragraph7')}
                        </div>
                    </div>
                </div>

                <div className={s.coin_block}>
                    <div className={s.content}>
                    <div className={s.text} dangerouslySetInnerHTML={{ __html: `${t('about_page.paragraph8')}<div class="${s.coin_container}"><img src="${coin}" alt=""/></div>` }} />


                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AboutUs;
