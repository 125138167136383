import React from 'react';
import s from './NavBar.module.css'
import {NavLink} from "react-router-dom";
import {HOME} from "../../router/Constants";
import PublicRoutes from "../../router/PublicRoutes";
import './index.css'
import { useTranslation } from 'react-i18next';

type RouteType = {
    path: string;
    title: string;
    component: JSX.Element;
};

const NavBar = () => {
    const { t } = useTranslation();
    const publicRoutes: RouteType[] = PublicRoutes()
    return (
        <div className={s.wrapper}>
            <NavLink className={s.link} to={HOME}>{t('home')}</NavLink>
            {publicRoutes.map((item: RouteType) => (
                <NavLink to={item.path} key={item.path} className={s.link}>
                    {item.title}
                </NavLink>
            ))}
        </div>
    );
};

export default NavBar;
