import React from 'react';
import s from "./Faq.module.css";
import {Collapse, theme} from 'antd';
import plus from '../../assets/img/plus.svg'
import minus from '../../assets/img/minus.png'
import {motion} from 'framer-motion';
import { useTranslation } from 'react-i18next';

const {Panel} = Collapse;

const {useToken} = theme;

const Faq = () => {
const {t} = useTranslation();
    const {token} = useToken();

    const dataForCollapse = [
        {
            title: t('faq_page.title1'),
            text: t('faq_page.text1'),
            key: "1",
        },
        {
            title: t('faq_page.title2'),
            text: t('faq_page.text2'),
            key: "2",
        },
        {
            title: t('faq_page.title3'),
            text: t('faq_page.text3'),
            key: "4",
        },
        {
            title: t('faq_page.title4'),
            text: t('faq_page.text4'),
            key: "5",
        },
        {
            title: t('faq_page.title5'),
            text: t('faq_page.text5'),
            key: "6",
        },
        {
            title: t('faq_page.title6'),
            text: t('faq_page.text6'),
            key: "7",
        },
        {
            title: t('faq_page.title7'),
            text: t('faq_page.text7'),
            key: "8",
        },
        {
            title: t('faq_page.title8'),
            text: t('faq_page.text8'),
            key: "9",
        },
    ]

    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        {t("faq")}
                    </div>
                    <div className={s.collapse_block}>
                        {
                            dataForCollapse.map((item) =>
                                <Collapse
                                    key={item.key}
                                    className={s.my_collapse}
                                    expandIconPosition={"end"}
                                    bordered={false}
                                    expandIcon={({isActive}) => isActive ?
                                        <img className={s.icon_minus} src={minus} alt=""/> :
                                        <img src={plus} className={s.icon_plus} alt=""/>}
                                    style={{
                                        background: "transparent",
                                        alignItems: 'center',
                                        transition: token.motionEaseInOut
                                    }}
                                >
                                    <Panel header={item.title} key={item.key} className={s.panelStyle}>
                                        <div className={s.collapse_text}>
                                            <p>{item.text}</p>
                                        </div>
                                    </Panel>
                                </Collapse>
                            )
                        }

                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default Faq;
